//前端常用方法，等同于utility.js
import cookie from "@/assets/help/cookie.js";
export default {
    cookie,
    //转换命名风格
    //toModeType: modeType、ModeType、mode_type、MODE_TYPE
    toNameStyle(name, toModeType = "modeType") {
        let strArr = [], endStr = "";
        while (name.indexOf("_") !== -1) {   //先把用_分隔开的字符串统一转为用大写字母隔开的字符串
            let ind = name.indexOf("_");
            name = name.replace("_", "");
            name = name.substring(0, ind) + name.substr(ind, 1).toUpperCase() + name.substr(ind + 1);
        }
        // 对用大写字母隔开的字符串分隔开，并装在strArr数组中
        let indsArr = [];
        for (let i = 0; i < name.length; i++) {
            let charCode = name.charCodeAt(i)
            if (charCode >= 65 && charCode <= 90) {   //这个区间内为大写字母
                i != 0 && indsArr.push(i)
            }
        }
        let sInd = 0, eInd = 0;
        let length = indsArr.length + 1
        for (let i = 0; i < length; i++) {
            eInd = i == length - 1 ? name.length : indsArr[i];
            strArr.push(name.substring(sInd, eInd));
            sInd = eInd;
        }
        switch (toModeType) {
            case "mode_type":
                strArr = strArr.map(item => {
                    return item.toLowerCase();
                });
                endStr = strArr.join("_");
                break;
            case "MODE_TYPE":
                strArr = strArr.map(item => {
                    return item.toUpperCase();
                });
                endStr = strArr.join("_");
                break;
            case "modeType":
                strArr = strArr.map((item, ind) => {
                    item.toLowerCase();
                    if (ind != 0) {
                        item = item.charAt(0).toUpperCase() + item.substr(1);
                    }
                    return item;
                })
                endStr = strArr.join("");
                break;
            case "ModeType":
                strArr = strArr.map(item => {
                    return item.charAt(0).toUpperCase() + item.substr(1);
                })
                endStr = strArr.join("");
                break;
            default: throw new Error(`命名风格暂不支持转换成"${toModeType}"类型`); break;
        }
        return endStr;
    },
    //获取值类型
    //返回值类型：undefined,boolean,number,nan,string,symbol,bigint,null,array,function,object
    getValType(val) {
        let type = typeof val;    //typeof能判断出 undefined、boolean、number、string、symbol、bigint、function、object
        if (type === "object") {
            if (val === null) {
                type = "null";
            } else if (val instanceof Array) {
                type = "array";
            } else if (val instanceof Object) {
                type == "object";
            }
        } else if (type === "number") {
            if (isNaN(val)) {
                type = "nan";
            }
        }
        return type;
    },
    //防抖：指触发事件后在 n 秒内函数只能执行一次，如果在 n 秒内又触发了事件，则会重新计算函数执行时间
    //fn事件处理函数  delay延迟时间  isImmediate 是否立即执行
    //使用示例：window.addEventListener('scroll', debounce(()=>console.log("防抖测试")));
    debounce(fn, delay = 1000, isImmediate = true) {
        let timer = null;
        return () => {
            if (timer) { clearTimeout(timer) };
            if (isImmediate) {
                let canExe = !timer;
                timer = setTimeout(() => {
                    timer = null;
                }, delay);
                if (canExe) fn();
            } else {
                timer = setTimeout(() => {
                    fn();
                    timer = null;
                }, delay);
            }
        }
    },
    //节流：指连续触发事件，但是在 n 秒内只执行一次函数
    //fn事件处理函数  delay延迟时间  isImmediate  是否立即执行
    //使用示例： window.addEventListener('scroll', throttle(()=>{console.log("节流测试")}));
    throttle(fn, delay = 3000, isImmediate = true) {
        let timer = null;
        let oldTime = Date.now();
        return () => {
            if (isImmediate) {
                let nowTime = Date.now();
                if (nowTime - oldTime > delay) {
                    fn();
                    oldTime = nowTime;
                }
            } else {
                if (!timer) {
                    timer = setTimeout(() => {
                        fn();
                        timer = null;
                    }, delay);
                }
            }
        }
    },
    //格式化日期
    //date：能被new Date()识别的都能接受
    //type:YYYY-MM-DD hh:mm:ss、YYYY-M-D h:m:s
    //注："-",":"可以自定义，例：YYYY/MM/DD HH-mm-ss
    formatTime(time = Date.now(), type = "YYYY-MM-DD hh:mm:ss") {
        let reg = /^YYYY.M{1}.D{1} h{1}.m{1}.s{1}$|^YYYY.M{2}.D{2} h{2}.m{2}.s{2}$/;
        if (!reg.test(type)) {
            throw new Error(`暂不支持转换成${type}日期格式`);
        }
        let typeArr = type.split(" ");
        let dateChar = typeArr[0].charAt(4), timeChar = "";
        if (typeArr[1]) timeChar = typeArr[1].charAt(type.includes("hh") ? 2 : 1);
        let isPadZero = type.match(/M/g).length === 2;
        let timeStr = new Date(time).toLocaleString('chinese', { hour12: false });
        timeStr = timeStr.split(" ").map((item, ind) => {
            let splitChar = ind == 0 ? "/" : ":";
            item = item.split(splitChar).map(ite => {
                ite = isPadZero ? ite.padStart(2, "0") : parseInt(ite);
                return ite;
            }).join(ind == 0 ? dateChar : timeChar);
            return item;
        }).join(" ");
        return timeStr;
    },
    //中文表示时间间隔
    // 返回值：n年前、n月前、n天前、n小时前、n分钟前、刚刚
    timeAgoByText(time) {
        let nowMs = new Date().getTime();
        let oldMs = new Date(time).getTime();
        let diffS = Math.floor((nowMs - oldMs)/1000);
        let text = "";
        switch (true) {
            case diffS<60: text = "刚刚"; break;
            case diffS>=60 && diffS<60*60: text = Math.floor(diffS/60) + "分钟前"; break;
            case diffS>=60*60 && diffS<60*60*24: text = Math.floor(diffS/(60*60)) + "小时前"; break;
            case diffS>=60*60*24 && diffS<60*60*24*30: text = Math.floor(diffS/(60*60*24)) + "天前"; break;
            case diffS>=60*60*24*30.5 && diffS<60*60*24*30.5*12: text = Math.floor(diffS/(60*60*24*30.5)) + "月前"; break;
            default: text = Math.floor(diffS/(60*60*24*30.5*12)) + "年前"; break;
        }
        return text;
    },
    //数组排序：字符串、数字元素类型数组均可（仅支持一维数组）
    // type（排序方式）: asc升序  desc降序  random随机
    arrSort(arr, type = 'asc') {
        //判断数组元素是否全都是数字类型或可以转为非NaN数字类型
        let isNumArr = !arr.some(item => isNaN(item));
        function randomSort() {
            return Math.random() > .5 ? -1 : 1;
        }
        if (isNumArr) {
            if (type == 'asc') {
                return arr.sort((a, b) => { return a - b });
            } else if (type == 'desc') {
                return arr.sort((a, b) => { return b - a });
            } else if (type == 'random') {
                return arr.sort(randomSort);
            }
        } else {
            if (type == 'asc') {
                return arr.sort();
            } else if (type == 'desc') {
                return arr.sort().reverse();
            }else if (type == 'random') {
                return arr.sort(randomSort);
            }
        }
    }
};