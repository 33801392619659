import axios from 'axios';
import app from '@/main.js';
import CryptoJS from '@/assets/SpiderHack';
import { formatSubForm } from '@/utils/tools';
// axios.defaults.headers['env'] = process.env.NODE_ENV
let request = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 20000,
  withToken: true, //这里是自行添加的，因为后端对不需要token的接口，前端传了token，后端会提示异常：token格式不对或token过期等。
  // withCredentials: false,   //表示跨域请求时是否需要使用凭证，默认false
  headers: {
    version: '2.0.6',
    app: 'web',
  },
});

/****** request拦截器==>对请求参数做处理 ******/
request.interceptors.request.use(
  (req) => {
    let parameter = formatSubForm(req.data ? req.data : {});
    const encryptedData = CryptoJS.encryptAES(parameter);
    const signature = encryptedData.slice(0, 64);
    if (signature) req.headers['X-signature'] = signature;
    req.data = parameter;
    if (req.withToken) {
      req.headers['Authorization'] = localStorage.getItem('token');
    }
    return req;
  },
  (err) => {
    return err;
  }
);
/****** respone拦截器==>对响应做处理 ******/
request.interceptors.response.use(
  (res) => {
    let { status, data } = res;
    if (status >= 200 && status < 300) {
      let { code, message } = data;
      if (code == 1011006 || code == 1011008) {
        app.$message.error(message);
        app.$router.push('/login');
      } else {
        return data;
      }
    } else {
      // console.log("请求错误状态码：" + status)
    }
  },
  (err) => {
    app.$message.error(err);
    return err;
  }
);
export default request;
