class Cookie {
    setItem(key, val, time = 24) { //time(单位：小时)
        let date = new Date();
        let expiresHours = time ? time : 1;
        date.setTime(date.getTime() + expiresHours * 3600 * 1000); //格式化为cookie识别的时间
        document.cookie = key + "=" + val + ";expires=" + date.toGMTString(); //设置cookie
    }
    getItem(key) {
        // let cookie = document.cookie.replace(/[ ]/g, ""); //获取cookie，并且将获得的cookie格式化，去掉空格字符
        let cookie = document.cookie;
        let arrCookie = cookie.split(";") //将获得的cookie以"分号"为标识 将cookie保存到arrCookie的数组中
        let val = null;
        for (let i = 0; i < arrCookie.length; i++) {
            let arr = arrCookie[i].split("=");
            if (key == arr[0]) {
                val = arr[1];
                break;
            }
        }
        return val;
    }
    delItem(key) {
        let date = new Date(); //获取当前时间
        date.setTime(date.getTime() - 1000); //将date设置为过去的时间
        document.cookie = key + "=0; expires =" + date.toGMTString(); //设置cookie
    }
    clearAll() {
        let keys = document.cookie.match(/[^ =;]+(?=\=)/g);
        if (keys) {
            for (let i = keys.length; i--;)
                document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString();
        }
    }
}
let cookie = new Cookie();
export default cookie;