import http from "@/assets/http.js";
export default {

    //全部订单 待发货 1
    //退款订单 待审核 2
    //进货订单 待收货 3 
    getcount(data) {
        return http({
            url: "/api/backstage/queryOrderQuantity",
            method: "post",
            data,
        });
    },
};