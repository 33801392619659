<template>
  <div class="login f-c-c">
    <div class="wrap bounce-in">
      <div class="head f-c-c">搜了商家后台管理中心</div>
      <el-form
        class="body"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item label="账号：" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="pwd">
          <el-input
            v-model="form.pwd"
            type="password"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item class="f-c-c">
          <el-button type="primary" @click="onSubmit">登录</el-button>
          <el-button @click="onReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      form: {
        phone: '',
        pwd: '',
      },
      rules: {
        phone: [
          {
            required: true,
            message: '请输入账号',
            trigger: 'blur',
          },
        ],
        pwd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.user.loginIn(this.form).then((res) => {
            let { data, message, success } = res;
            if (success) {
              this.$store.commit('storeLoginInfo', data);
              localStorage.setItem('store', JSON.stringify(this.$store.state));
              localStorage.setItem('token', data.token);
              this.$router.push('/manage/shop/info');
            } else {
              this.$message.error(message);
            }
          });
        } else {
          this.$message.warning('请按提示信息正确填写');
        }
      });
    },

    onReset() {
      this.$refs.form.resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  height: 100vh;
  background: url('../assets/img/bg/login.jpg') no-repeat 0 0/100% 100% #eee;
}

.wrap {
  width: 500px;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;

  .head {
    color: #ff5000;
    font-weight: 600;
    font-size: 30px;
    height: 130px;
    background: #eee;
  }

  .body {
    padding: 50px 70px;
  }
}
</style>
