/**
 * 此处放置页面视图相关api
 */
import http from "@/assets/http.js";
export default {
    /**
     * 获取菜单信息
     * @account    账号
     * @password   密码
     */
    getMenus(data) {
        return http({
            url: '/getMenus',
            method: 'post',
            data
        })
    },
    /**
     * 获取省市区地址
     */
    getAddress(data) {
        localStorage.setItem("lastGetAddressDate", Date.parse(new Date()));
        return http({
            url: '/api/common/address/getAddress',
            method: 'post',
            data
        })
    },
    //  
}